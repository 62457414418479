// Composable used for managing the CSV Uploader form state.

// <!-- API -->
import { ref, computed } from 'vue';
import { useECNBCache, ECNBCache } from '@/hooks/store/useECNBCache';
import {
    useUploadStore,
    UploadStore,
} from '~CSVUploader/hooks/store/useUploadStore';
import { useRoute } from 'vue-router';

// <!-- COMPOSABLES -->
import { ComposableConfig, ComposableModule } from '@/hooks/useComposable';
import {
    useUploadWorkflow,
    UploadFormWorkflow,
} from '~CSVUploader/hooks/workflow/useUploadWorkflow';
import {
    UploadFormData,
    useUploadData,
} from '~CSVUploader/hooks/data/useUploadData';

// <!-- TYPES -->
import { FormStepDefinition } from '@/hooks/useFormStep';
import { Bases, Factors, FormatFileSize } from '@/utils/FormatFileSize';

import locations from '@/api/accounts/locations';

// <!-- CLASSES -->
/**
 * @class
 * Location form expected props.
 */
export class UploadFormProps {
    /**
     * Save props to this object and give them expected types.
     * @param {Record<String, any>} props
     */
    constructor(props) {
        /** @type {V.ComputedRef<Boolean>} Determine if the form is in debug mode. */
        this.debug = computed(() => props?.debug?.value ?? false);
    }
}

/**
 * @class
 * Upload form configuration.
 * @template {Record<String, any>} [Props=any]
 * @template {V.EmitsOptions} [E=any]
 * @extends {ComposableConfig<UploadFormProps, E, UploadFormConstants, UploadFormState, UploadFormProperties, UploadFormHandlers, UploadFormMethods>}
 */
export class UploadFormConfig extends ComposableConfig {
    /**
     * Construct the configuration object.
     * @param {Props} props
     * @param {V.SetupContext<E>} context
     */
    constructor(props, context) {
        super(new UploadFormProps(props), context);
        /** @type {Router.RouteLocationNormalizedLoaded} Loaded route. */
        this.route = useRoute();
        /** @type {UploadStore} */
        this.store = useUploadStore();
        /** @type {ECNBCache} */
        this.cache = useECNBCache(this.store.api.store);
        /** @type {UploadFormData} Data controller. */
        this.data = useUploadData(this);
        /** @type {UploadFormWorkflow} Workflow controller. */
        this.workflow = useUploadWorkflow(this);
    }

    /**
     * Initialize the composable.
     * @returns {Promise<this>}
     */
    async initializeComposable() {
        this.isInitialized = false;

        // Initialize core modules.
        this.initializeConstants()
            .initializeState()
            .initializeProperties()
            .initializeMethods()
            .initializeHandlers();

        // Initialize the additional modules.
        await this.workflow.initialize();
        await this.handlers.onFormInit();
        this.isInitialized = true;
        return this;
    }

    initializeConstants() {
        new UploadFormConstants(this);
        return this;
    }

    initializeState() {
        new UploadFormState(this);
        return this;
    }

    initializeProperties() {
        new UploadFormProperties(this);
        return this;
    }

    initializeHandlers() {
        new UploadFormHandlers(this);
        return this;
    }

    initializeMethods() {
        new UploadFormMethods(this);
        return this;
    }
}

/**
 * @class
 * Local form constants.
 * @extends {ComposableModule<UploadFormConfig>}
 */
export class UploadFormConstants extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {UploadFormConfig} config Input configuration.
     */
    constructor(config) {
        super('constants', config);
        const { store } = config;

        /** Configuration settings. */
        const { fileCountLimit, fileSizeLimit } =
            store.api.create.live.uploader.config();

        /** File size limit in megabytes. */
        const fileSizeLimitMB = FormatFileSize(
            fileSizeLimit,
            Bases.BYTE,
            Factors.MEGABYTE
        );

        /** Step details and ids. */
        const { StepIDs, StepDetails } = {
            /** Step IDs. */
            StepIDs: /** @type {const} */ ([
                'select:files',
                'assign:locations',
                'assign:profiles',
                'form:summary',
            ]),
            /** @type {[ id: StepIDs[Number], definition: FormStepDefinition ][]} */
            get StepDetails() {
                return [
                    [
                        'select:files',
                        FormStepDefinition.prepare()
                            .using.label('Select Files')
                            .using.description(
                                `Select up to ${fileCountLimit} files. Max size: ${fileSizeLimitMB}`
                            ),
                    ],
                    [
                        'assign:locations',
                        FormStepDefinition.prepare()
                            .using.label('Assign Locations')
                            .using.description(
                                'Assign locations for each dataset file selected.'
                            ),
                    ],
                    [
                        'assign:profiles',
                        FormStepDefinition.prepare()
                            .using.label('Map Fields')
                            .using.description(
                                'Assign mapping profiles for each dataset file selected. If this operation is cancelled, uploaded datasets will not be ingested.'
                            ),
                    ],
                    [
                        'form:summary',
                        FormStepDefinition.prepare()
                            .using.label('Upload Summary')
                            .using.description(
                                'Review summary of dataset upload.'
                            ),
                    ],
                ];
            },
        };

        // EXPOSE
        /** @type {Number} */
        this.fileCountLimit = fileCountLimit;
        /** @type {Number} */
        this.fileSizeLimit = fileSizeLimit;
        /** @type {StepIDs} */
        this.StepIDs = StepIDs;
        /** @type {StepDetails} */
        this.StepDetails = StepDetails;
    }
}

/**
 * @class
 * Local form state.
 * @extends {ComposableModule<UploadFormConfig>}
 */
export class UploadFormState extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {UploadFormConfig} config Input configuration.
     */
    constructor(config) {
        super('state', config);

        const { store } = config;
        /**
         * Confirm delete modal state.
         */
        this.confirmDeleteModal = ref({
            isOpen: false,
            title: 'Cancel Upload?',
            prompt: 'This will discard your current selection of files.',
            cancel: 'Cancel',
            confirm: 'Delete',
        });
    }
}

/**
 * @class
 * Local form state.
 * @extends {ComposableModule<UploadFormConfig>}
 */
class UploadFormProperties extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {UploadFormConfig} config Input configuration.
     */
    constructor(config) {
        super('properties', config);

        const { store, cache } = config;

        /** // TODO: Is the form ready? @type {V.ComputedRef<Boolean>} */
        this.isFormReady = computed(() => true);

        /** // TODO: Is the form loading? @type {V.ComputedRef<Boolean>} */
        this.isFormLoading = computed(() => {
            const records = store.api.state.uploader.data.records;
            const whereIngesting = [...records.values()].filter(
                (r) => r.isDatasetBatchIngesting
            );
            return records.size > 0 && whereIngesting.length > 0;
        });
    }
}

/**
 * @class
 * Local form handlers.
 * @extends {ComposableModule<UploadFormConfig>}
 */
class UploadFormHandlers extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {UploadFormConfig} config Input configuration.
     */
    constructor(config) {
        super('handlers', config);

        const { store, cache, state, props, methods, workflow } = config;

        // <!-- LIFECYCLE HOOKS -->

        /**
         * Run when first initializing.
         */
        this.onFormInit = async () => {
            await workflow.start();
        };

        // TODO: OnFormInit.
        // TODO: OnFormReset.
        // TODO: OnFormError.

        // TODO: OnStepInit.
        // TODO: OnStepReset.

        /**
         * Signal an alert has been raised by the current step.
         * @param {*} event
         */

        this.onStepAlert = async (event) => {
            // TODO: Register an alert on the Vuex store.
        };

        /**
         * Handle cancel workflow event.
         * @param {*} event
         */

        this.onCancelWorkflow = async (event) => {
            console.warn(`[cancel::workflow]`);
            this.onHideConfirmDeleteModal();
            await workflow.restart();
        };

        /**
         * Handle resume workflow event.
         * @param {*} event
         */

        this.onResumeWorkflow = async (event) => {
            console.warn(`[resume::workflow]`);
            this.onHideConfirmDeleteModal();
        };

        // <!-- MODAL HOOKS -->

        this.onShowConfirmDeleteModal = (
            title = 'Cancel Upload?',
            prompt = 'This will discard your current selection of files.',
            confirm = 'Delete'
        ) => {
            state.confirmDeleteModal.value = {
                title,
                prompt,
                confirm,
                cancel: 'Cancel',
                isOpen: true,
            };
        };

        this.onHideConfirmDeleteModal = (
            title = 'Cancel Upload?',
            prompt = 'This will discard your current selection of files.',
            confirm = 'Delete'
        ) => {
            state.confirmDeleteModal.value = {
                title,
                prompt,
                confirm,
                cancel: 'Cancel',
                isOpen: false,
            };
        };
    }
}
/**
 * @class
 * Local form methods.
 * @extends {ComposableModule<UploadFormConfig>}
 */
class UploadFormMethods extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {UploadFormConfig} config Input configuration.
     */
    constructor(config) {
        super('methods', config);

        const { store, cache, workflow, state } = config;

        // <!-- LIFECYCLE METHODS -->

        /**
         * Initialize the upload form state.
         */
        this.activate = () => {
            console.groupCollapsed(`[form::activate]`);
            console.groupEnd();
        };

        /**
         * Deactivate the upload form state.
         * Reset the progress.
         */
        this.deactivate = () => {
            console.groupCollapsed(`[form::deactivate]`);
            console.groupEnd();
        };

        /**
         * Get step component.
         * @param {String} id
         * @returns {String}
         */
        this.getStepComponent = (id) => {
            if (id !== undefined && workflow.StepComponents.size > 0) {
                return workflow.StepComponents.get(id);
            }
            return null;
        };

        /** Prevent editing of the profile. */
        this.isProfileEditingAllowed = () => {
            const records = store.api.state.uploader.data.records;
            const whereIngesting = [...records.values()].filter(
                (r) => r.isDatasetBatchIngesting
            );
            const whereIngested = [...records.values()].filter(
                (r) => r.isDatasetBatchIngested
            );
            const hasRecords = records.size > 0;
            const hasIngesting = whereIngesting.length > 0;
            const hasIngested = whereIngested.length > 0;
            return hasRecords && !hasIngesting && !hasIngested;
        };
    }
}

/**
 * @template {V.EmitsOptions} [E=any]
 * Use Location form state.
 * @param {UploadFormProps} props Location form props.
 * @param {V.SetupContext<E>} context Setup context.
 */
export const useUploadForm = (props, context) => {
    // Prepare the configuration.
    const config = new UploadFormConfig(props, context);

    // INIT
    config.initializeComposable();

    // EXPOSE
    return config;
};

// <!-- EXPORTS -->
export default {
    useUploadForm,
};
